<template>
  <!-- Table Container Card -->
  <div
    v-if="
      getUser.role.slug === 'responsable-relation-client' ||
      getUser.role.slug === 'responsable-commercial' ||
        getUser.role.slug === 'charge-daffaires' ||
        getUser.role.slug === 'charge-de-clientele' ||
        getUser.role.slug === 'comptable' ||
        getUser.role.slug === 'assistante-administrative' ||
        getUser.role.slug === 'super-administrateur'
    "
  >
    <b-card>
      <b-card-header>
        <h5>Filtrages</h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="10"
            lg="10"
            class="mb-md-0 mb-2"
          >
            <b-row>
              <b-col
                cols="12"
                sm="6"
                md="4"
                lg="4"
              >
                <label for="">Client</label>
                <v-select
                  v-model="payloadFilter.customer_id"
                  :options="customerOptions"
                  class="invoice-filter-select"
                  label="name"
                  :reduce="(customer) => customer.id"
                >
                  <template v-slot:no-options>
                    <b-spinner
                      v-if="isCustomersWithoutPaginationLoading"
                      style="width: 2.5rem; height: 2.5rem"
                      class="align-middle text-info"
                    />
                    <span
                      v-else
                      class="mt-1 font-medium-1"
                    >Aucun client ne correspond</span>
                  </template>
                </v-select>
              </b-col>

              <b-col
                v-if="
                  getUser.role.slug === 'responsable-relation-client' ||
                    getUser.role.slug === 'charge-daffaires' ||
                    getUser.role.slug === 'charge-de-clientele' ||
                    getUser.role.slug === 'super-administrateur'
                "
                cols="12"
                sm="6"
                md="4"
                lg="4"
              >
                <label for="">Statut du compte</label>
                <v-select
                  v-model="payloadFilter.is_activated"
                  :options="statusOptions"
                  class="invoice-filter-select"
                  :reduce="(item) => item.value"
                >
                  <template #selected-option="{ label }">
                    <span class="text-truncate overflow-hidden">
                      {{ label }}
                    </span>
                  </template>
                </v-select>
              </b-col>
              <b-col
              cols="12"
              md="6"
              lg="4"
              sm="4" 
              v-if="
                  getUser.role.slug === 'responsable-relation-client' ||
                    getUser.role.slug === 'charge-daffaires' ||
                    getUser.role.slug === 'charge-de-clientele' ||
                    getUser.role.slug === 'super-administrateur'
                " 
            >
            <label for="">Date d'inscription</label>
              <b-form-group>
                <flat-pickr
                  v-model="rangeDate"
                  class="form-control"
                  placeholder="Sélectionner une intervalle de date"
                  :config="config"
                />
              </b-form-group>
            </b-col>

              <!-- <b-col
                v-if="
                  getUser.role.slug === 'super-administrateur' ||
                    getUser.role.slug === 'responsable-commercial'
                "
                cols="12"
                sm="6"
                md="3"
                lg="3"
              >
                <label for="">Invité par</label>
                <b-form-select
                  v-if="
                    businessManagerOptions.length === 1 &&
                      businessManagerOptions[0].disabled
                  "
                  id="charge_daffaire_id"
                  :options="businessManagerOptions"
                />
                <v-select
                  v-else
                  id="role"
                  v-model="payloadFilter.invited_by"
                  :reduce="(businessManager) => businessManager.id"
                  label="full_name"
                  :options="businessManagerOptions"
                >
                  <span
                    slot="no-options"
                    @click="$refs.select.open = false"
                  >
                    Chargement...
                  </span>
                </v-select>
              </b-col> -->

            </b-row>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            md="2"
            lg="2"
            class="mt-2"
          >
            <b-button
              variant="primary"
              @click="applyFilterCustomersAction()"
            >
              <div v-if="isReLoadFilterData">
                <span> Chargement ... </span>
                <b-spinner small />
              </div>

              <span
                v-else
                class="text-nowrap font-medium-1"
              >Filtrer</span>
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-card>
      <b-row>
        <b-col
          cols="12"
          md="3"
          class="d-flex align-items-center justify-content-start mb-2"
        >
          <b-button
            variant="primary"
            @click="reLoadDataCustomersAction()"
          >
            <div v-if="isReLoadDataCustomers">
              <span> Chargement ... </span>
              <b-spinner small />
            </div>
            <span
              v-else
              class="text-nowrap font-medium-1"
            >Actualiser</span>
          </b-button>
        </b-col>
      </b-row>

      <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :is-loading="isCustomersLoading"
      >
        <template slot="emptystate">
          <div class="text-center">
            <span> {{ isCustomersLoading ? "" : "Liste vide" }} </span>
          </div>
        </template>
        <template slot="loadingContent">
          <div class="text-center text-info">
            <b-spinner class="align-middle" />
          </div>
        </template>

        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: Name -->
          <div
            v-if="props.column.field === 'full_name'"
            class="text-nowrap text-center align-middle"
          >
            <span class="d-block text-nowrap text-info font-small-5">{{
              props.row.full_name
            }}</span>

            <span
              v-if="props.row.phone_number"
              class="text-success font-small-4"
            >{{
              props.row.phone_number
            }}</span>
          </div>

          <!-- Column: Status -->
          <div
            v-else-if="props.column.field === 'setIsActivated'"
            class="align-middle text-center font-small-5"
          >
            <b-badge
              pill
              :variant="`light-${resolveIsActivatedVariant(props.row.is_activated)}`"
              class="text-capitalize text-nowrap font-medium-1"
            >
              {{ props.row.setIsActivated }}
            </b-badge>
          </div>

          <!-- Column: Commandes Ponctuelles -->
          <div
            v-else-if="props.column.field === 'orders_count'"
            class="align-middle text-center font-small-5"
          >
            <span class="font-small-5 text-info">
              {{ props.row.orders_count }}
            </span>
          </div>

          <!-- Column: Commandes Récurrentes -->
          <div
            v-else-if="props.column.field === 'recurring_orders_count'"
            class="align-middle text-center font-small-5"
          >
            <span class="font-small-5 text-info">
              {{ props.row.recurring_orders_count }}
            </span>
          </div>

          <!-- Column: Date d'inscription-->
          <div
            v-else-if="props.column.field === 'created_at'"
            class="align-middle text-center font-small-5"
          >
            <span class="font-small-5 text-info">
              {{ moment(props.row.created_at).locale('fr').format('llll') }}
            </span>
          </div>

          <!-- Column: Responsable -->
          <div
            v-else-if="props.column.field === 'referred_by'"
            class="align-middle text-center font-small-5"
          >
            <span
              v-if="props.row.referred_by"
              class="font-small-5 text-info"
            >
              {{ props.row.referred_by.full_name }}
            </span>
            <span
              v-else
              class="font-small-5 text-info"
            > -------- </span>
          </div>

          <!-- Column: Action -->
          <div
            v-else-if="props.column.field === 'action'"
            class="align-middle text-center"
          >
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="20"
                    class="text-info align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  :id="`details${props.row.id}`"
                  class="itemActions cursor-pointer"
                  @click="putCustomerInStoreAction(props.row)"
                >
                  <feather-icon
                    icon="EyeIcon"
                    class="text-info mr-1"
                    size="25"
                  />
                  <span>Détails</span>
                </b-dropdown-item>
                <b-dropdown-item
                  :id="`delete${props.row.id}`"
                  class="itemActions cursor-pointer"
                  @click="applyDeleteCustomerAction(props.row.id)"
                >
                  <feather-icon
                    class="text-danger mr-1"
                    icon="TrashIcon"
                    size="25"
                  />
                  <span>Supprimer</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </div>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
      </vue-good-table>

      <div class="d-flex justify-content-between mt-2 flex-wrap">
        <b-button
          :disabled="metaData.prev_page_url == null"
          variant="outline-secondary"
          @click="applyLoadMoreCustomersAction(metaData.prev_page_url)"
        >
          <span class="text-white text-nowrap font-medium-2">Page précédente</span>
        </b-button>

        <div>
          <span
            class="text-white font-medium-2"
          >{{ metaData.current_page }} sur {{ metaData.last_page }} pages</span>
        </div>

        <div>
          <span
            class="text-white font-medium-2"
          >Total :{{ total }}</span>
        </div>

        <b-button
          :disabled="metaData.next_page_url == null"
          variant="outline-secondary"
          @click="applyLoadMoreCustomersAction(metaData.next_page_url)"
        >
          <span class="text-nowrap font-medium-1 text-white">Page suivante</span>
        </b-button>
      </div>
    </b-card>
  </div>
  <div v-else>
    <not-authorized />
  </div>
</template>

<script>
import {
  BCard,
  BTab,
  BTabs,
  BRow,
  BCol,
  BFormInput,
  BDropdown,
  BDropdownItem,
  BPagination,
  BSpinner,
  BBadge,
  BButton,
  BBreadcrumb,
  BBreadcrumbItem,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import store from '@/store/index'
import NotAuthorized from '@/layouts/components/NotAuthorized.vue'

// import CustomersInDeptList from './CustomersInDeptList.vue'

export default {
  components: {
    BCard,
    BTab,
    BTabs,
    BRow,
    BCol,
    BFormInput,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    BBadge,
    vSelect,
    BButton,
    BBreadcrumb,
    BBreadcrumbItem,
    VueGoodTable,
    NotAuthorized,
    flatPickr,
    // CustomersInDeptList,
  },

  data() {
    return {
      rangeDate: null,
      config: { mode: 'range' },
      total: '',
      payloadFilter: {
        customer_id: '',
        is_activated: '',
        start_date: '',
        end_date: '',
        invited_by: '',
      },
      managerOptions: [],
      customerOptions: [],
      businessManagerOptions: [],
      statusOptions: [
        {
          label: 'Activé',
          value: 1,
        },
        {
          label: 'Non activé',

          value: 0,
        },
      ],
      isReLoadFilterData: false,

      isReLoadDataCustomers: false,
      dir: false,
      rows: [],
      columns: [
        {
          label: 'Client',
          field: 'full_name',
        },
        {
          label: 'Status du compte',
          field: 'setIsActivated',

        },
        {
          label: 'Commandes Ponctuelles',
          field: 'orders_count',
        },
        {
          label: 'Commandes Récurrentes',
          field: 'recurring_orders_count',
        },
        {
          label: 'Date d\'inscription',
          field: 'created_at',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
    }
  },

  computed: {
    ...mapGetters('customers', ['getCustomers', 'getCustomersWithoutPagination']),
    ...mapGetters(['isCustomersLoading', 'isCustomersWithoutPaginationLoading']),
    ...mapGetters('auth', ['getUser']),

    metaData() {
      const meta = {
        prev_page_url: '',
        next_page_url: '',
        current_page: '',
        last_page: '',
        current_page_url: '',
      }
      if (this.getCustomers != null) {
        meta.prev_page_url = this.getCustomers.prev_page_url
        meta.next_page_url = this.getCustomers.next_page_url
        meta.current_page = this.getCustomers.current_page
        meta.last_page = this.getCustomers.last_page
        meta.current_page_url = `${this.getCustomers.path}?page=${this.getCustomers.current_page}`
        this.total = this.getCustomers.total
      }
      return meta
    },

    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },

  watch: {
    payloadFilter: {
      handler(newValue, oldValue) {
        const keys = Object.keys(newValue)
        keys.forEach(key => {
          if (newValue[key] == null) {
            newValue[key] = ''
            console.log(this.payloadFilter)
          }
        })
      },
      deep: true,
    },
    rangeDate(val) {
      if (val != null && val.split('to').length === 2) {
        this.applyFilterCustomersAction(val)
      }
    },
    isCustomersWithoutPaginationLoading(val) {
      if (val === false) {
        this.getCustomersWithoutPagination.forEach(element => {
          this.customerOptions.push({
            id: element.id,
            name: element.customer_full_name,
          })
        })
      }
    },

    isCustomersLoading(val) {
      if (val === false) {
        this.rows = this.customTabIsActivated(this.getCustomers.data)
      }
    },
  },

  created() {
    console.log('CCC', this.getCustomers)
    this.getCustomers !== null
      ? (this.rows = this.customTabIsActivated(this.getCustomers.data))
      : null
  },

  mounted() {
    if (this.getUser.role.slug === 'super-administrateur') {
      this.columns.splice(4, 0, {
        label: 'Invité par',
        field: 'referred_by',
      })
    }

    if (this.getCustomersWithoutPagination.length != 0) {
      this.getCustomersWithoutPagination.forEach(element => {
        this.customerOptions.push({
          id: element.id,
          name: element.customer_full_name,
        })
      })
    }
  },

  methods: {
    ...mapActions('orders', ['getCustomerOrdersAction']),
    ...mapActions('customers', [
      'getCustomersAction',
      'loadMoreCustomersAction',
      'deleteCustomerAction',
      'getCustomersWithoutPaginationAction',
      'filterCustomersAction',
    ]),

    applyFilterCustomersAction(val) {
      const allIsNUl = Object.values(this.payloadFilter).every(el => el === '')
      if (allIsNUl && val == 0) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Warning',
              icon: 'CheckIcon',
              text: 'Veuillez sélectionner au moins un champ du filtre.',
              variant: 'warning',
            },
          },
          {
            position: 'top-center',
          },
        )
      } else {
        if (val !== undefined) {
          this.payloadFilter.start_date = val.split('to')[0],
          this.payloadFilter.end_date = val.split('to')[1]
        } else {
          this.payloadFilter.start_date = ''
          this.payloadFilter.end_date = ''
        }
        this.isReLoadFilterData = true
        this.$store.commit('SET_IS_CUSTOMERS_LOADING', true)
        this.rows = []
        this.filterCustomersAction(this.payloadFilter)
          .then(response => {
            this.isReLoadFilterData = false
            this.$store.commit('SET_IS_CUSTOMERS_LOADING', false)
            this.rows = this.customTabIsActivated(this.getCustomers)
            this.total = this.rows.length
          })
          .catch(error => {
            this.isReLoadFilterData = false
            this.$store.commit('SET_IS_CUSTOMERS_LOADING', false)
            console.log(error)
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Erreur',
                  icon: 'TriangleAlertIcon',
                  text: error.response.data.message,
                  variant: 'danger',
                },
              },
              {
                position: 'top-center',
              },
            )
          })
      }
    },

    reLoadDataCustomersAction() {
      this.payloadFilter = {
        customer_id: '',
        is_activated: '',
        start_date: '',
        end_date: '',
        invited_by: '',
      }
      this.isReLoadDataCustomers = true
      this.$store.commit('SET_IS_CUSTOMERS_LOADING', true)
      this.rows = []
      this.getCustomersAction()
        .then(response => {
          this.isReLoadDataCustomers = false
          this.$store.commit('SET_IS_CUSTOMERS_LOADING', false)
          this.rangeDate = null
        })
        .catch(error => {
          this.isReLoadDataCustomers = false
          this.$store.commit('SET_IS_CUSTOMERS_LOADING', false)
        })
    },

    applyLoadMoreCustomersAction(url) {
      this.$store.commit('SET_IS_CUSTOMERS_LOADING', true)
      this.rows = []
      this.loadMoreCustomersAction(url)
        .then(() => {
          this.$store.commit('SET_IS_CUSTOMERS_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_CUSTOMERS_LOADING', false)
        })
    },

    putCustomerInStoreAction(customer) {
      this.$store.commit('customers/SET_CUSTOMER', customer)
      this.$router.push({
        name: 'admin-customers-details',
        params: { id: customer.id },
      })
    },

    applyDeleteCustomerAction(id) {
      this.$swal({
        title: 'Suppression',
        text: 'Êtes-vous sûr de vouloir supprimer ce client?',
        icon: 'warning',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: 'Non',
        allowOutsideClick: false,
        confirmButtonText: 'Oui',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
        preConfirm: async () => {
          await this.deleteCustomerAction(id)
          return this.applyLoadMoreCustomersAction(this.metaData.current_page_url)
        },
      })
        .then(response => {
          console.log(response)
          if (response.value) {
            this.applyLoadMoreCustomersAction(this.metaData.current_page_url)
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Succès',
                  icon: 'CheckIcon',
                  text: 'Client supprimée avec succès',
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
          }
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },

    resolveUserStatusVariant: status => {
      if (status === 1) return 'info'
      if (status === 0) return 'warning'
      if (status === 3) return 'success'
      return 'primary'
    },

    resolveStatusText: status => {
      if (status === 1) return 'Offre proposée'
      if (status === 0) return 'En attente'
      if (status === 3) return 'Terminée'
      return 'Payée'
    },

    resolveIsActivatedVariant: is_activated => {
      if (is_activated === true) return 'success'
      return 'warning'
    },

    customTabIsActivated(array) {
      const tab = []
      array.forEach(element => {
        if (element.is_activated === true) {
          element.setIsActivated = 'Compte activé'
        } else {
          element.setIsActivated = 'Compte non activé'
        }
        tab.push(element)
      })
      return tab
    },

    customTab(array) {
      const tab = []
      array.forEach(element => {
        switch (element.status) {
          case 0:
            element.setStatus = 'En attente'
            break
          case 1:
            element.setStatus = 'Offre proposée'
            break
          case 2:
            element.setStatus = 'Payée'
            break
          case 3:
            element.setStatus = 'Terminée'
            break
          default:
            element.setStatus = null
        }
        tab.push(element)
      })
      return tab
    },
  },
}
</script>

<style>
i.icon-code {
  display: none !important;
}

.vgt-select {
  background: transparent !important;
  border-color: #404656 !important;
}

.itemActions :hover {
  background: transparent !important;
}
</style>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.item-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
.v-list .v-list-item--active {
  background-color: green !important;
}
.v-list .v-list-item--active .v-list-item__title {
  color: #ffd54f !important;
}
</style>
